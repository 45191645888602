//Specific JS for this skin
require(['jquery'], function ($) {
    $('#mini-cart .hide-below-960').html("<i class='fa fa-shopping-cart'></i>");
    $('.form-search .button-search').html("<i class='fa fa-search'></i>");
    
    //Remove certain classes from the footer to expand it to the full width
    $('#footer .footer-top-container .footer-top').removeClass('footer container');
  
    //Change the layout for category pages to show 3 products in a row for devices > 767px and < 1200px;
      if ($(window).width() > 767 && $(window).width() < 1200) {
        $('ul.category-products-listing').removeClass('itemgrid-4col');
        $('ul.category-products-listing').addClass('itemgrid-3col');
      }
      //Remove the hover effect from category pages
      $('ul.category-products-listing').removeClass('hover-effect');

      //Remove the More Info text from being shown on hover
      $('ul.category-products-listing .item').each(function() {
        $('.display-onhover', this).hide();
        $('.display-onhover', this).removeClass('display-onhover');
      });
});
